import { PortalTarget } from 'portal-vue'
import { h, inject } from 'vue'
import { routeLocationKey, RouterView } from 'vue-router'

import ToolbarApp from '../components/ToolbarApp.vue'
import UnsupportedBrowser from '../components/UnsupportedBrowser.vue'

export default function DefaultLayout () {
  // @todo(vue3-migration): Change this to useRoute once we are done with @vue/compat.
  // Using useRoute here somehow breaks the tests
  const route = inject(routeLocationKey)

  return [
    h(UnsupportedBrowser),
    h(ToolbarApp, route.meta),
    h(RouterView),
    h(PortalTarget, { name: 'after-footer', multiple: true })
  ]
}
