/**
 * @typedef {Object} Auth
 *
 * @property {string|null} dealerId - The ID of the current dealer.
 * @property {string|null} error - Error message or object indicating the current error state.
 * @property {string} flHost - The host URL for feature login services.
 * @property {Object|null} group - The currently selected group (if any).
 * @property {string} host - The host URL for authentication services.
 * @property {boolean} isThirdPartyCookiesDisabled - Whether third-party cookies are disabled.
 * @property {string|null} memberId - The ID of the authenticated member.
 * @property {Map<string, boolean>} permissions - A map tracking permissions for various resources and actions.
 * @property {string|null} token - The current authentication token.
 * @property {boolean} redirectTimedOut - Whether a redirect operation timed out.
 * @property {string|null} loadingMessage - A message describing the current loading state.
 * @property {Object|null} user - The authenticated user's information.
 *
 * @property {Object} dealer - The current dealer node object (derived from `auth.user`).
 * @property {boolean} hasError - Whether there is a current error (`auth.error` is truthy).
 * @property {boolean} hasGroupAccess - Whether the user has access to the current group.
 * @property {boolean} loading - Whether the app is in a loading state (no user and no error).
 * @property {string} name - The name of the authenticated user (profile name or username).
 * @property {string} sub - The username (subject) of the authenticated user.
 * @property {Array} groupDealers - Dealers in the user's group (filtered by `auth.group`).
 *
 * @property {function(string): void} reportError - Reports an error to Datadog.
 * @property {function(): Promise<void>} checkHash - Validates credentials from the URL hash and updates auth state.
 * @property {function(): Promise<void>} setup - Initializes the authentication state.
 * @property {function(): void} initCookiesIframe - Creates an iframe to check for third-party cookie support.
 * @property {function(): void} initAssistedIframe - Creates an iframe to assist with authentication.
 * @property {function(): void} login - Redirects the user to the login page.
 * @property {function(): void} logout - Redirects the user to the logout page.
 * @property {function({token: string, memberId: string, dealerId: string}): Promise<void>} setAuthState - Updates the authentication state with new credentials.
 * @property {function({id: string, name: string}): void} setGroup - Sets the current group in the state.
 * @property {function(string, string, string): void} switchDealerGroup - Switches to a different dealer group.
 * @property {function(string, {targetPath?: string, openNewTab?: boolean}): void} switchDealer - Switches to a different dealer.
 * @property {function(string, boolean, string): string} redirectUrl - Constructs a redirect URL for authentication operations.
 * @property {function(string): Promise<Object>} validateToken - Validates an authentication token with the backend.
 * @property {function(string): Promise<void>} setUserHomepage - Sets the user's homepage preference.
 * @property {function(string, string): Promise<boolean>} fetchPermission - Fetches the user's permission for a specific action on a resource.
 * @property {function(string, string): Promise<boolean>} checkPermission - Checks the user's cached or fetched permission for a resource/action.
 */

import { datadogRum } from '@datadog/browser-rum'
import { reactive, inject } from 'vue'

import { client } from '@maxsystems/client'

import { checkPermission, getUser, setUserHomepage } from '../queries'
import { setupDevtools } from './auth.devtools'
import { hasDealerGroupAccess } from '~/shared/dealer-group-access'

/**
 * Symbol used to inject the `auth` object into the Vue application.
 * It can be imported to mock the `auth` object in tests.
 */
export const authSymbol = Symbol('auth')

/**
 * Provides the `auth` object into the setup function.
 * @returns {Auth} The `auth` object.
 * @throws {Error} If the `auth` object is not provided in the Vue application.
 *
 * @example
 * import { useAuth } from './authPlugin';
 *
 * export default {
 *   setup() {
 *     const auth = useAuth();
 *
 *     if (!auth.user) {
 *       auth.login();
 *     }
 *
 *     return { auth };
 *   },
 * };
 */
export function useAuth () {
  const auth = inject(authSymbol)
  if (!auth) throw new Error('Auth not found')
  return auth
}

/** @type {import('vue').Plugin} */
const plugin = {
  install (app) {
    app.config.globalProperties.$auth = auth
    app.provide(authSymbol, auth)
    setupDevtools(app, auth)
  }
}

/** @type {Auth} */
export const auth = reactive({
  dealerId: null,
  error: null,
  flHost: process.env.FL_HOST,
  group: null,
  host: process.env.SSO_HOST,
  isThirdPartyCookiesDisabled: true,
  memberId: null,
  permissions: new Map(),
  token: null,
  redirectTimedOut: false,
  loadingMessage: null,
  user: null
})

Object.defineProperties(auth, {
  dealer: {
    get: () => auth.user?.dealer?.node || {}
  },
  hasError: {
    get: () => Boolean(auth.error)
  },
  hasGroupAccess: {
    get: () => Boolean(auth.user && hasDealerGroupAccess(auth.user, auth.groupDealers))
  },
  loading: {
    get: () => Boolean(!auth.user && !auth.hasError)
  },
  name: {
    get: () => auth.user?.profile?.name || auth.user?.username
  },
  sub: {
    get: () => auth.user?.username
  },
  groupDealers: {
    get: () => {
      if (!auth.user?.dealers?.nodes) return []
      const groupId = auth.group?.id || auth.dealer?.group?.id
      if (!groupId) return []
      return auth.user.dealers.nodes.filter(
        dealer => dealer.group?.id === groupId
      )
    }
  },
  isHendrickGroup: {
    get: () => {
      const hendrickGroupId = '100068'
      return hendrickGroupId === auth.group?.id
    }
  }
})

export const authOnMessageAbort = new AbortController()

auth.reportError = error => {
  datadogRum.addError(new Error(error))
}

auth.checkHash = async () => {
  auth.loadingMessage = 'Validating credentials...'
  const token = new URLSearchParams(window.location.hash.slice(1)).get('access_token')
  const { memberId, dealerId } = await auth.validateToken(token)

  if (!memberId || !dealerId) return auth.login()

  auth.setAuthState({ token, memberId, dealerId })

  const urlWithoutHash = `${window.location.pathname}${window.location.search}`
  history.replaceState(null, null, urlWithoutHash)
}

auth.setup = async () => {
  if (/^\/for\/\d+/.test(location.pathname)) return

  auth.initCookiesIframe()

  if (window.location.hash) return auth.checkHash()

  auth.initAssistedIframe()
}

auth.initCookiesIframe = () => {
  if (document.getElementById('cookies-iframe')) return

  const cookies = document.createElement('iframe')
  cookies.style.display = 'none'
  cookies.src = auth.host + '/cookies'
  cookies.id = 'cookies-iframe'
  document.body.appendChild(cookies)

  window.addEventListener('message', ({ origin, data }) => {
    if (!origin.includes(auth.host)) return
    if (typeof data === 'string' && data.includes('MAX:3PC')) {
      auth.isThirdPartyCookiesDisabled = data === 'MAX:3PC:0'
      if (auth.isThirdPartyCookiesDisabled) {
        window.dataLayer.push({ event: 'cookies_disabled' })
      }
      cookies.remove()
    }
  }, { signal: authOnMessageAbort.signal })
}

auth.initAssistedIframe = () => {
  if (document.getElementById('assisted-iframe')) return

  const assisted = document.createElement('iframe')
  assisted.style.display = 'none'
  assisted.src = auth.host
  assisted.id = 'assisted-iframe'

  window.addEventListener('message', ({ origin, data }) => {
    if (!origin.includes(auth.host)) return
    if (data.error) return auth.login()

    const { access_token: token, memberId, dealerId } = data

    if (!token) return
    auth.setAuthState({ token, memberId, dealerId })
  }, { signal: authOnMessageAbort.signal })

  document.body.appendChild(assisted)
}

auth.login = () => {
  auth.loadingMessage = 'Redirecting to ACV MAX...'

  window.location.replace(auth.redirectUrl('login', true))

  setTimeout(() => {
    auth.redirectTimedOut = true
    auth.loadingMessage = 'ACV MAX is taking a long time to respond. Check your network connection.'
  }, 3000)
}

auth.logout = () => {
  window.location.replace(auth.redirectUrl('logout'))
}

auth.setAuthState = async ({ token, memberId, dealerId }) => {
  const breadcrumbCategory = 'auth'

  auth.error = null
  auth.loadingMessage = 'Logging you in...'

  datadogRum.setGlobalContext({ dealerId, memberId })
  datadogRum.addAction(breadcrumbCategory, { message: `Authenticated user #${memberId} for dealer #${dealerId}` })

  auth.token = token
  auth.memberId = memberId
  auth.dealerId = dealerId

  client.config({ token })

  let isHanging = false

  const warningTimeout = setTimeout(() => {
    auth.error = {
      message: 'Loading is taking longer than expected. Would you like to try again?',
      label: 'Retry',
      action: () => {
        getUser.abort()
        isHanging = true
        auth.setAuthState({ token, memberId, dealerId })

        auth.reportError(`${breadcrumbCategory}: The user retried after a timeout on fetching user info`)
      }
    }
  }, 10000)

  const { data, errors } = await getUser.fetch({ id: memberId, dealer: dealerId })

  if (isHanging) return

  clearTimeout(warningTimeout)

  if (!data || errors) {
    datadogRum.addAction(breadcrumbCategory, { data: { data, errors } })
    auth.error = {
      message: 'An unexpected error has occurred.',
      action: auth.login,
      label: 'Try Again'
    }
    auth.reportError(`${breadcrumbCategory}:an unexpected error has occurred when getting user info`)
    return
  }

  if (!data?.user?.dealer?.node) {
    datadogRum.addAction(breadcrumbCategory, { data: { data, errors: [new Error('User not authorized to view any dealership information')] } })
    auth.error = {
      message: 'You are not authorized to view any dealership information.',
      action: auth.logout,
      label: 'Log Out'
    }
    auth.reportError(`${breadcrumbCategory}:user is not authorized to view any dealership information`)
    return
  }

  auth.error = null
  auth.user = data?.user
  auth.setGroup(auth.dealer.group)

  window.dataLayer.push({
    event: 'login',
    userId: memberId,
    username: auth.user.username,
    dealer: auth.dealer.name,
    businessUnit: {
      groupId: auth.dealer.group.id,
      zip: auth.dealer.address.postalCode,
      groupName: auth.dealer.group.name,
      businessunitcode: auth.dealer.internalId.bucode,
      address: auth.dealer.address.street,
      businessunitid: dealerId,
      state: auth.dealer.address.region,
      dealerName: auth.dealer.name,
      city: auth.dealer.address.locality
    },
    member: {
      loginId: auth.user.username,
      lastName: auth.user.profile?.lastName,
      phone: auth.user.profile?.phone,
      email: auth.user.profile?.email,
      userId: auth.user.id,
      fullName: auth.user.profile?.name,
      firstName: auth.user.profile?.firstName
    }
  })

  const fmsHome = document.createElement('iframe')
  fmsHome.style.display = 'none'
  fmsHome.src = auth.flHost + '/fl-ims/secured/fmsHome'
  fmsHome.id = 'fms-home'
  document.body.appendChild(fmsHome)

  datadogRum.setUser({
    id: auth.user.id,
    name: auth.user.profile?.name,
    email: auth.user.profile?.email,
    dealerId,
    dealerName: auth.dealer.name,
    groupId: auth.dealer.group.id,
    groupName: auth.dealer.group.name
  })

  datadogRum.setGlobalContext({ dealer: auth.dealer.name })
}

auth.setGroup = ({ id, name }) => {
  auth.group = { id, name }
}

auth.switchDealerGroup = (id, name, targetPath) => {
  location.assign(auth.redirectUrl(`switch/${id}`, false, targetPath))
}

auth.switchDealer = (buid, { targetPath, openNewTab } = {}) => {
  const switchDealer = openNewTab
    ? window.open.bind(window)
    : location[targetPath ? 'assign' : 'replace'].bind(window.location)
  switchDealer(auth.redirectUrl(`switch/${buid}`, false, targetPath))
}

auth.redirectUrl = (path, keepOriginalPath = false, targetPath = '') => {
  const { origin, pathname = '', search = '' } = window.location
  const url = new URL(auth.host)
  url.search = new URLSearchParams({
    redirect_uri: origin + (keepOriginalPath ? pathname + search : targetPath)
  }).toString()
  url.pathname = 'api/' + path
  return url.toString()
}

auth.validateToken = async token => {
  const res = await fetch(auth.host + '/api/token', {
    credentials: 'include',
    headers: { Authorization: 'Bearer ' + token }
  })

  return res.json()
}

auth.setUserHomepage = async homepage => {
  const userId = auth.user.id
  const { errors } = await setUserHomepage.fetch({ userId, homepage })

  if (errors?.length) throw new Error(errors[0].message)

  auth.user.profile.homepage = homepage
}

auth.fetchPermission = async (resource, action) => {
  const key = `${resource}:${action}`
  auth.permissions.pending = auth.permissions.pending || new Map()
  if (auth.permissions.pending.get(key)) return await auth.permissions.pending.get(key)

  auth.permissions.pending.set(key, (async () => {
    const { data } = await checkPermission.fetch({
      id: auth.memberId,
      resource,
      action
    })

    return Boolean(data?.user?.hasPermission)
  })())

  const hasPermission = await auth.permissions.pending.get(key)
  auth.permissions.pending.delete(key)

  auth.permissions.set(`${resource}:${action}`, hasPermission)
  return hasPermission
}

auth.checkPermission = async (resource, action) => {
  const answer = auth.permissions.get(`${resource}:${action}`)
  if (answer === undefined) return await auth.fetchPermission(resource, action)
  return answer
}

Object.seal(auth)

export default plugin
